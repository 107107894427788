import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';

const SoloGuidesCore: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo solo-guide'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Core Review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_core.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Core Review</h1>
          <h2>Core's in Solo Leveling: Arise explained.</h2>
          <p>
            Last updated: <strong>27/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader
          title="What are they and Where to get them
"
        />
        <p>
          Introduced with the release of the Upper Floors of the Demons’ Castle,
          Cores are the latest progression system that will greatly impact both
          Sung Jinwoo And your Hunters, giving massive stats as well as having
          special effects depending on the sets they belong to. For the time
          being, Cores only affect the characters they are equipped to, and
          they’ll quickly become a great source of Stats and TP, massively
          boosting the power of any character when the proper Cores are used.
        </p>
        <p>
          For now, their availability is extremely limited and they can only be
          acquired by defeating the various stages of the Workshop of Brilliant
          Light (WOBL), with the Burning series sets being moved to the classic
          Artifact Dungeons starting from Level12. Here’s a quick breakdown of
          where the Cores can be looted and what they do:
        </p>
        <ul>
          <li>
            The First Slot Cores will be focused on offense, always giving ATK
            as the main stat and having damage buffing set effects, the two
            current sets giving either Cdmg to Basic Skills on Ult or a simple
            ATK% buff at all times. They can be acquired in any difficulty of
            both Vulcan and Baran’s dungeons;
          </li>
          <li>
            The Second Slot Cores will boost defense, with DEF as their main
            stat and giving the equipped characters purely defensive buffs from
            the set effects, like Shields or Damage Reduction. You can obtain
            them starting from Medium Vulcan and Easy Baran;
          </li>
          <li>
            On the other hand, the Third Slot Cores can only be dropped when
            defeating the Normal mode Bosses of Baran’s dungeon, making them
            much more difficult to gather compared to the other two types. Those
            will give HP as main stat, and their set effects will be focused on
            utility, such as MP Regen or Power Gauge charge.
          </li>
        </ul>
        <p>
          All Cores will come with a random secondary stat, ranging from Crit
          Rate to MP Recovery Rate or even Damage Reduction. Those secondary
          stats can NOT give you any of the ATK, DEF or HP stats however so keep
          that in mind when you assess the viability of a Core.
        </p>
        <SectionHeader title="The Best Cores" />

        <p>
          When assessing the Core’s worth, you’ll have to look at 3 main angles
          right from the beginning, and the order of importance will vary based
          on your account’s progress:
        </p>
        <ul>
          <li>
            For the majority of people, the most important aspect will be the
            secondary stat. Getting Damage Reduction on an ATK Core will
            drastically lower its value, regardless of set and Element;
          </li>
          <li>
            If you rolled a good secondary stat, the 2nd thing to look for will
            be the Core’s Element and set. Indeed, the set effect will only be
            active if the Core is equipped to a Hunter of the same Element, SJW
            having his own version of the Cores with a little SJW head instead;
          </li>
          <li>
            The 3rd, and least important criterion when you are not pushing the
            very endgame content, will be the rarity of the Core. It goes
            without saying that Legendary Cores will be stronger than Heroic
            ones, and that the latter will similarly outshine the Rare Cores.
            Nonetheless, having enough Cores for all your most used Hunters, as
            the TP boost and the Raw stats will be noticeable.
          </li>
        </ul>
        <p>
          With that said, the priority isn’t entirely set in stone. If you don’t
          mind swapping Cores before every fight, it can be useful to hyperfocus
          on a couple of Offensive Cores in order to benefit greatly from the
          massive Raw stats they’ll give to your Carries (DEF Cores will be
          considered as Offensive options for Hunters like Chairman Go and
          Charlotte, and HP will be the same for Harper and Seo Jiwoo for
          example), the set effects being powerful but not as impactful as the
          sheer amount of stats you’ll get from a high level Core with good
          stats.
        </p>
        <SectionHeader title="What sets to aim for?" />
        <ul>
          <li>
            For the 1st Core slot, Ancient Wraith set (ATK%) will be the King
            for any ATK based carry except potentially Mirei. For any carry
            using DEF or HP, you’ll be defaulting to the Nameless Demon set
            (Cdmg for Basic Skills on Ult);
          </li>
          <li>
            For the 2nd slot, the Nameless Demon will excel when defending
            against Burst, but will otherwise offer little value when facing
            packs of enemies and high DPS foes. Still, this slot offers more
            leeway and you should focus mostly on the raw stats;
          </li>
          <li>
            In the 3rd’s slot case, Ancient Wraith is once again stealing the
            spotlight, with a powerful Power Gauge charge effect whenever you
            perform 3 Basic Attacks (10s CD). The Nameless Demon isn’t any good,
            giving back at most 5% MP whenever your character Ults (at 2,000
            Mana this is a 100MP refund, which is not even a Basic Skill’s
            cost). For most characters you’ll still give more importance to the
            raw stats, but when it comes to scoring content 3% Power Gauge on up
            to 10 characters over the course of an entire boss fight is a big
            deal.
          </li>
        </ul>
        <SectionHeader title="What stats to aim for?" />
        <p>
          For the stats, you’ll follow more or less the same pattern as the
          artifact recommendations:
        </p>
        <ul>
          <li>
            For the main stats, the Flat stats will be most useful for all
            Hunters, while SJW might prefer ATK %, DEF Flat and HP Flat;
          </li>
          <li>
            For the secondary stats, this will mostly depend on your Artifacts:
            Crit Rate will be awesome if you can't reach 50% with your Artifacts
            and will be the highest priority in that case. You should also
            consider crit rate cores if it lets you drop ‘bad pieces’ of gear
            with average crit rate value, to expand and minmax the offensive
            stats you can get. Otherwise, Defense Penetration, Crit Damage and
            Damage Increase will be favored on Carries, with some exceptions
            like Mirei benefiting greatly from Additional MP (and even SJW if
            you play mostly on AUTO). Note that for Guild Boss, many Carries can
            run out of MP within the first couple of minutes, so you can
            consider MP secondary stats in that case, but be careful when
            building such niche Cores at first.
          </li>
          <li>
            For Supports and non damage oriented Breakers, you’ll be looking at
            mostly Additional MP, or any MP related effect if you can’t find the
            perfect mix of main stat + second stat + element + set. Special
            mention for Min Byung-Gu, who still benefits greatly from Crit
            Damage even as a Support.
          </li>
        </ul>
        <SectionHeader title="Upgrading Cores?" />
        <p>
          You’ve got a good Core, now you naturally want to upgrade it. But how?
        </p>
        <p>
          Well, there aren’t too many options as of now: you’ll have to feed it
          other Cores to increase its Level, sacrificing the offerings in the
          process. This is going to be very costly at first, Cores being very
          scarce so be sure not to waste your fodder on upgrading average Cores.
          Once you take your Cores to Level 10, you’ll likely still be very far
          from maxing its stats, and that’s where the Solidified Stones will
          become relevant.
        </p>
        <p>
          You will only be able to use Solidified Stones of a similar rarity as
          the Core you’re enhancing, with the Tier 3 Stones being very rare
          drops on Normal Mode Baran only. You’ll have to craft most of those
          materials, and for this reason it’s not recommended to spend any
          Solidified Stones on Cores below Legendary Tier.
        </p>
        <p>
          Ideally, if you don’t need a core immediately, it doesn’t hurt to wait
          until you get the right stat combination with a starting value of
          15-20%. This way you will decrease the number of upgrade materials you
          will need to use when maxing out your core!
        </p>
        <p>
          Lastly, it’s good to know that once you decide to change Cores for
          better ones, those getting replaced can be used to give more
          experience to the newer Cores, even though the ratio isn’t amazing.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesCore;

export const Head: React.FC = () => (
  <Seo
    title="Core Review | Solo Leveling: Arise | Prydwen Institute"
    description="Core's in Solo Leveling: Arise explained."
    game="solo"
  />
);
